import { gql } from "apollo-angular";
import { Issn } from "../interfaces/issn";
import {
  PublishingGoalsFieldsPage,
} from "../interfaces/publishing-goals-fields-page";

export const GET_PUBLISHING_GOALS_FIELDS_PAGE_DATA = gql<
  {
    page: PublishingGoalsFieldsPage;
    issn: Issn;
  },
  void
>`
  query {
    page: gulf_publishing_fields_goals {
      title
      image {
        id
        filename: filename_download
        title
      }
      magazineGoals: magazine_goals
      magazineInterests: magazine_interests
      magazineGoalsImage: magazine_goals_image {
        id
        filename: filename_download
        title
      }
    }

    issn: gulf_magazine_issn {
      issn
    }
  }
`;
