<app-page>
  <page-header></page-header>
  <page-content>
    <div id="versions" class="my-5">
      <div class="container">
        <div class="text-end">
          <form class="d-inline-block mb-3" action="">
            <div class="search-form input-with-icon">
              <i class="fa-solid fa-magnifying-glass"></i>
              <input
                (input)="searchForPublication($event)"
                type="text"
                class="form-control"
                placeholder="رقم العدد"
              />
            </div>
          </form>
        </div>
        <div class="row">
          <div
            *ngFor="let issue of issues"
            class="col-lg-3 col-md-4 col-sm-6 mb-4 cursor-pointer"
          >
            <div [routerLink]="issue.number" class="version-card">
              <div class="img-wrapper">
                <img [src]="numsImage | imageLink" class="" alt="صورة العدد" />
              </div>
              <h6 class="fw-bold text-dark text-center">رسالة الخليج العربي</h6>
              <h6 class="fw-bold text-primary text-center">
                العدد {{ issue.number }}
              </h6>
            </div>
          </div>
          <div *ngIf="issues?.length === 0" class="no-data">لا توجد أعداد</div>
        </div>

        <div class="mt-5">
          <!-- start pagination -->
          <nav *ngIf="totalPages > 0">
            <ul class="pagination justify-content-center dark d-flex" style="flex-wrap: wrap" >
              <li
                *ngFor="let item of [].constructor(totalPages); index as i"
                (click)="showItemsByPage(i + 1)"
                class="page-item"
                [ngClass]="{ active: currentPage === i + 1 }"
              >
                <a class="page-link">{{ i + 1 }}</a>
              </li>
            </ul>
          </nav>

          <!-- end pagination  -->
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
