import {gql} from "apollo-angular";
import {ContactUsPage} from "../interfaces/contact-us-page";

export const GET_CONTACT_US_PAGE = gql<{page: ContactUsPage}, void>`
  query {
    page: contact_us_page {
      title
      subtitle: sub_title
      image {
        id
        filename: filename_download
        title
      }
      location
      phone
      email
    }
  }
`;
