<section class="bg-primary bg-opacity-10">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div
          style="max-width: 350px"
          class="ratio ratio-1x1 mx-auto ms-md-auto me-md-0"
        >
          <img
            [src]="aboutMagazineImage | imageLink"
            class="w-100 h-100 cover"
            alt=""
          />
        </div>
      </div>
      <div class="col mt-5 mt-md-0">
        <div class="me-md-1">
          <p class="fw-bold text-primary">عن المجلة</p>
          <h5 class="fw-bold mb-2">مجلة رسالة الخليج</h5>
          <h5 class="fw-bold mb-2">RISALAT UL-KHALEEJ AL-ARABI</h5>

          <p [innerHTML]="aboutMagazineDescription"></p>

          <div class="text-end mt-5">
            <a routerLink="/about-us" class="btn btn-dark px-4">التفاصيل</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
