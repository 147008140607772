import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { Publication } from "../previous-publications/previous-publications.component";
import { Issue } from "../previous-issues/previous-issues.component";
import { IssueResearchesPageData } from "src/app/interfaces/issue-researches-page";
import { ImageFile } from "src/app/interfaces/image-file";
@Component({
  selector: "app-issue-researches",
  templateUrl: "./issue-researches.component.html",
  styleUrls: ["./issue-researches.component.scss"],
})
export class IssueResearchesComponent implements OnInit {
  issueNumber!: number;
  publications!: Publication[];
  issue!: Issue;
  numsImage!: ImageFile;
  constructor(private route: ActivatedRoute, private http: HttpClient) {}
  environment = environment;
  ngOnInit(): void {
    this.issueNumber = this.route.snapshot.params["issueNumber"];

    const page = this.route.snapshot.data["page"] as IssueResearchesPageData;
    this.numsImage = page.data.homePage.numsImage;
    this.getIssue();
    this.getPublication();
  }
  getPublication() {
    this.http
      .get<any>(
        `${environment.magazine_API}/api/publications?number=${this.issueNumber}`
      )
      .subscribe((data: any) => {
        this.publications = data.data;
      });
  }

  getIssue(): void {
    this.http
      .get<any[]>(
        `${environment.magazine_API}/api/issues?number=${this.issueNumber}`
      )
      .subscribe((data: any) => {
        this.issue = data.data[0];
      });
  }
}
