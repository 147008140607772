import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { Observable, map } from "rxjs";
import { publishingPolicyAndEthicsPageData } from "../interfaces/publishing-policy-and-ethics-page";
import { GET_PUBLISHING_POLICY_AND_ETHICS_PAGE } from "../queries/publishing-policy-and-ethics-page.query";

@Injectable({
  providedIn: "root",
})
export class PublishingPolicyAndEthicsPageResolver
  implements Resolve<publishingPolicyAndEthicsPageData>
{
  constructor(private apollo: Apollo) {}

  resolve(): Observable<publishingPolicyAndEthicsPageData> {
    return this.apollo
      .query({
        query: GET_PUBLISHING_POLICY_AND_ETHICS_PAGE,
      })
      .pipe(
        map((result): publishingPolicyAndEthicsPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: "سياسة وأخلاقيات النشر",
            hero: {
              title: meta.title,
              image: meta.image,
            },
            data: page,
            meta,
          };
        })
      );
  }
}
