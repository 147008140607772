<section id="about-2">
  <div class="container">
    <div class="cards-container">
      <div
        [routerLink]="term.link"
        *ngFor="let term of terms"
        class="single-card cursor-pointer"
      >
        <img [src]="term.icon" [alt]="term.title" />
        <h5 class="fw-bold mt-3">{{ term.title }}</h5>
      </div>
    </div>
  </div>
</section>
