import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { EditingStaffPageData } from "src/app/interfaces/editing-staff-page";
import { EditingStaff } from "src/app/interfaces/landing-page";

@Component({
  selector: "app-editing-staff",
  templateUrl: "./editing-staff.component.html",
  styleUrls: ["./editing-staff.component.scss"],
})
export class EditingStaffComponent implements OnInit {
  editingStaff!: EditingStaff[];

  manager!: EditingStaff;
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as EditingStaffPageData;
    this.manager = page.data.editingStaff.filter((staff) => staff.isManager)[0];
    this.editingStaff = page.data.editingStaff.filter(
      (staff) => staff.isManager === false
    );
  }
}
