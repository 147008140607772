<app-page>
  <page-header></page-header>
  <page-content>
    <div id="domains" class="my-5">
      <div class="container">
        <div class="row">
          <div class="col-md-9 mt-4">
            <div class="row">
              <div class="col-md-3 mb-5 mb-md-0">
                <img
                  class="rounded-3 d-block w-100 img-fluid cover"
                  style="aspect-ratio: 1"
                  [src]="publishingGoalsFields.magazineGoalsImage | imageLink"
                  [alt]="publishingGoalsFields.magazineGoalsImage.title"
                />
              </div>
              <div class="col">
                <h5 class="fw-bold">أهداف المجلة</h5>
                <div
                  [innerHTML]="publishingGoalsFields.magazineGoals"
                  class="small"
                ></div>

                <hr class="my-5" />
                <h5 class="fw-bold">اهتمامات المجلة ومجالات النشر</h5>
                <div
                  [innerHTML]="publishingGoalsFields.magazineInterests"
                  class="small"
                ></div>
              </div>
            </div>
          </div>
          <div class="col mt-4">
            <div class="bg-gold text-light p-4 rounded-3">
              <h6 class="fw-bold mb-3">الرقم المعياري الدولي (ISSN):</h6>
              <p class="small mb-0" [innerHTML]="issn.issn"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
