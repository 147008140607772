<section id="team">
  <div class="container">
    <p class="fw-bold text-primary text-center">هيئة التحرير</p>
    <h4 class="fw-bold mb-4 text-center">فريق عمل المجلة</h4>

    <div flickity [flickityConfig]="flickityConfig" class="slider">
      <div *ngFor="let singleStaff of staff" class="single-slide">
        <a routerLink="committees/{{ singleStaff.id }}" target="_self">
          <div class="committee-card card">
            <div class="card-body p-4">
              <img
                class="committee-img"
                style="width: 108px"
                [src]="singleStaff.avatar | imageLink"
                [alt]="singleStaff.avatar.title"
              />
              <div class="fs-6 text-center fw-bold">
                <div class="committee-name mb-1">{{ singleStaff.name }}</div>
                <div class="committee-position">{{ singleStaff.jobTitle }}</div>
                <hr class="my-3 mx-3" />
                <div class="committee-description">
                  {{ singleStaff.jobDescription }}
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
