import { environment } from "src/environments/environment";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import ar from "@angular/common/locales/ar";

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatPaginatorModule } from "@angular/material/paginator";

import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { InMemoryCache } from "@apollo/client/core";

import { LightgalleryModule } from "lightgallery/angular";

import { AppRoutingModule } from "./app-routing.module";

import { FlickityDirective } from "./directives/flickity.directive";
import { SlickItemDirective } from "./directives/slick-item.directive";
import { CounterDirective } from "./directives/counter.directive";

import { ImageLinkPipe } from "./pipes/image-link.pipe";
import { FileLinkPipe } from "./pipes/file-link.pipe";
import { FileTypePipe } from "./pipes/file-type.pipe";

import { AppComponent } from "./app.component";
import { CopyrightComponent } from "./components/shared/copyright/copyright.component";
import { FooterLinksComponent } from "./components/shared/footer-links/footer-links.component";
import { FontsShareComponent } from "./components/shared/fonts-share/fonts-share.component";
import { SlickCarouselComponent } from "./components/shared/slick-carousel/slick-carousel.component";
import { LoaderComponent } from "./components/shared/loader/loader.component";
import { PageComponent } from "./components/shared/page/page.component";
import { PageContentComponent } from "./components/shared/page/page-content/page-content.component";
import { PageHeaderComponent } from "./components/shared/page/page-header/page-header.component";
import { PageFooterComponent } from "./components/shared/page/page-footer/page-footer.component";
import { NavbarComponent } from "./components/shared/navbar/navbar.component";
import { HeroComponent } from "./components/shared/hero/hero.component";
import { BreadcrumbComponent } from "./components/shared/breadcrumb/breadcrumb.component";
import { GalleryComponent } from "./components/shared/gallery/gallery.component";

import { LandingPageComponent } from "./components/pages/landing-page/landing-page.component";

import { YouTubePlayerModule } from "@angular/youtube-player";
import { LandingHeroComponent } from "./components/pages/landing-page/landing-hero/landing-hero.component";
import { LandingNewsLatestNumbersComponent } from "./components/pages/landing-page/landing-news-latest-numbers/landing-news-latest-numbers.component";
import { LandingAboutNewsComponent } from "./components/pages/landing-page/landing-about-news/landing-about-news.component";
import { LandingPublishingConditionsComponent } from "./components/pages/landing-page/landing-publishing-conditions/landing-publishing-conditions.component";
import { LandingResearchesApplyComponent } from "./components/pages/landing-page/landing-researches-apply/landing-researches-apply.component";
import { LandingEditingStaffComponent } from "./components/pages/landing-page/landing-editing-staff/landing-editing-staff.component";
import { EditingStaffComponent } from "./components/pages/editing-staff/editing-staff.component";
import { AboutUsComponent } from "./components/pages/about-us/about-us.component";
import { PublishingFieldsGoalsComponent } from "./components/pages/publishing-fields-goals/publishing-fields-goals.component";
import { PublishingTermsComponent } from "./components/pages/publishing-terms/publishing-terms.component";
import { ProceduresStagesComponent } from "./components/pages/procedures-stages/procedures-stages.component";
import { PreviousIssuesComponent } from "./components/pages/previous-issues/previous-issues.component";
import { PreviousPublicationsComponent } from "./components/pages/previous-publications/previous-publications.component";
import { StaffComponent } from "./components/pages/editing-staff/staff/staff.component";
import { IssueResearchesComponent } from "./components/pages/issue-researches/issue-researches.component";
import { PublishingPolicyAndEthicsComponent } from "./components/pages/publishing-policy-and-ethics/publishing-policy-and-ethics.component";

const angular = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
];

const material = [
  MatDatepickerModule,
  MatInputModule,
  MatFormFieldModule,
  MatIconModule,
  MatButtonModule,
  MatPaginatorModule,
  YouTubePlayerModule,
];

const thirdParties = [ApolloModule, LightgalleryModule];

const directives = [FlickityDirective, SlickItemDirective, CounterDirective];

const pipes = [ImageLinkPipe, FileLinkPipe, FileTypePipe];

const components = [
  AppComponent,
  CopyrightComponent,
  FooterLinksComponent,
  FontsShareComponent,
  SlickCarouselComponent,
  LoaderComponent,
  PageComponent,
  PageContentComponent,
  PageHeaderComponent,
  PageFooterComponent,
  NavbarComponent,
  HeroComponent,
  BreadcrumbComponent,
  GalleryComponent,
  LandingPageComponent,
  LandingHeroComponent,
  LandingNewsLatestNumbersComponent,
  LandingAboutNewsComponent,
  LandingPublishingConditionsComponent,
  LandingResearchesApplyComponent,
  LandingEditingStaffComponent,
  EditingStaffComponent,
  AboutUsComponent,
  PublishingFieldsGoalsComponent,
  PublishingTermsComponent,
  ProceduresStagesComponent,
  PreviousIssuesComponent,
  PreviousPublicationsComponent,
  StaffComponent,
];

registerLocaleData(ar);

@NgModule({
  declarations: [
    ...directives,
    ...pipes,
    ...components,
    IssueResearchesComponent,
    PublishingPolicyAndEthicsComponent,
  ],
  imports: [...angular, ...material, ...thirdParties, AppRoutingModule],
  providers: [
    ...pipes,
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.graphqlURI,
          }),
        };
      },
      deps: [HttpLink],
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
