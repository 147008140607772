<section
  id="query"
  class=""
  style="background-image: url('assets/images/home-query.png')"
>
  <div class="container">
    <div class="text-center text-white">
      <p class="title fw-bold">{{ applyTitle }}</p>
      <p class="subtitle opacity-50">
        {{ applyDescription }}
      </p>
      <a
        href="{{ magazineSystemURL }}"
        target="_blank"
        class="btn btn-lg btn-light px-6 py-2 mt-4 fw-bold"
        >التقديم</a
      >
      <br />
      <a
        href="{{ magazineSystemURL }}"
        target="_blank"
        class="btn btn-link fw-bold mt-4"
        >طريقة التقديم من خلال الموقع</a
      >
    </div>
  </div>
</section>
