import { gql } from "apollo-angular";
import { PreviousPublicationsPage } from "../interfaces/previous-publications-page";

export const GET_PREVIOUS_PUBLICATIONS_PAGE = gql<
  { page: PreviousPublicationsPage },
  void
>`
  query {
    page: gulf_previous_publications {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
  }
`;
