import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ReplaySubject, takeUntil } from "rxjs";
import { Direction } from "src/app/enums/direction";
import { LandingHeroSlide } from "src/app/interfaces/landing-page";
import { LocalizationService } from "src/app/services/localization.service";

@Component({
  selector: "app-landing-hero",
  templateUrl: "./landing-hero.component.html",
  styleUrls: ["./landing-hero.component.scss"],
})
export class LandingHeroComponent implements OnInit, OnDestroy {
  flickityConfig: Flickity.Options = {
    rightToLeft: true,
    cellAlign: "right",
    wrapAround: true,
    contain: true,
    pageDots: false,
  };

  unsubscribe$ = new ReplaySubject(1);
  @Input() heroSlides!: LandingHeroSlide[];

  constructor(private localizationService: LocalizationService) {}

  ngOnInit(): void {
    this.localizationService
      .getLocale()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((locale) => {
        this.flickityConfig.rightToLeft =
          locale.direction === Direction.RIGHT_TO_LEFT;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
}
