import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable, of } from "rxjs";
import { SingleStaffPageData } from "../interfaces/editing-staff-page";
import { GET_SINGLE_STAFF_PAGE } from "../queries/editing-staff-page.query";

@Injectable({
  providedIn: "root",
})
export class SingleStaffPageResolver implements Resolve<SingleStaffPageData> {
  constructor(private apollo: Apollo) {}

  resolve(route: ActivatedRouteSnapshot): Observable<SingleStaffPageData> {
    const staffId = route.params["staffId"];
    return this.apollo
      .query({
        query: GET_SINGLE_STAFF_PAGE,
        variables: {
          staffId,
        },
      })
      .pipe(
        map((result) => {
          const { page: meta, ...page } = result.data;          
          return {
            title: meta.title,
            hero: {
              title: meta.title,
              image: meta.image,
            },
            breadcrumbs: [{ title: meta.title }],
            data: page,
            meta,
          };
        })
      );
  }
}
