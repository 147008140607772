import {
  Component,
  OnDestroy,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { ReplaySubject, takeUntil } from "rxjs";
import { ContactUsPage } from "src/app/interfaces/contact-us-page";
import { CMSService } from "src/app/services/cms.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-footer-links",
  templateUrl: "./footer-links.component.html",
  styleUrls: ["./footer-links.component.scss"],
})
export class FooterLinksComponent implements OnInit, OnDestroy {
  SCROLL_OFFSET = 300;

  contact = {
    location: "المملكة العربية السعودية ،الرياض، طريق الملك سعود، الوزارات",
    phone: "+966112664470",
    email: "journal@abegs.org",
  };
  portalURI = environment.portalURI;
  @ViewChild("scrollTopBtn") scrollTopButtonRef!: ElementRef<HTMLButtonElement>;

  unsubscribe$ = new ReplaySubject(1);

  footerLinks = [
    {
      title: "هيئة التحرير",
      href: "/committees",
      isActive: true,
    },
    {
      title: "عن المجلة",
      href: "/about-us",
    },
    {
      title: "أهداف ومجالات النشر",
      href: "/publishing-fields-goals",
    },
    {
      title: "شروط النشر",
      href: "/publishing-terms",
    },
    {
      title: "الإجراءات والمراحل",
      href: "/procedures-stages",
    },
    {
      title: "سياسة وأخلاقيات النشر",
      href: "/policy-and-ethics",
    },
    {
      title: "الأعداد السابقة",
      href: "/news-previous-numbers",
    },
    {
      title: "البحوث السابقة",
      href: "/previous-researches",
    },
  ];
  constructor(private cmsService: CMSService) {}

  ngOnInit() {
    // this.fetchContactUsDetails();
  }

  @HostListener("window:scroll")
  onWindowScroll() {
    if (!this.scrollTopButtonRef) return;

    if (window.scrollY > this.SCROLL_OFFSET) {
      this.scrollTopButtonRef.nativeElement.classList.add("shown");
    } else {
      this.scrollTopButtonRef.nativeElement.classList.remove("shown");
    }
  }

  fetchContactUsDetails() {
    this.cmsService
      .getContactUsDetails()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.contact = result;
        },
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
}
