import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ContactUsPage } from "src/app/interfaces/contact-us-page";
import {
  LandingHeroSlide,
  EditingStaff,
  LandingPage,
  LandingMagazineNumbers,
  LandingPageData,
} from "src/app/interfaces/landing-page";
import AOS from "aos";
import { ImageFile } from "src/app/interfaces/image-file";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit {
  meta!: LandingPage;
  heroSlides!: LandingHeroSlide[];
  staff!: EditingStaff[];
  contactus!: ContactUsPage;
  numbers!: LandingMagazineNumbers[];
  aboutMagazineDescription!: string;
  aboutMagazineImage!: ImageFile;
  applyDescription!: string;
  applyTitle!: string;
  magazineNumbersImage!: ImageFile;
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const { meta, ...page } = this.route.snapshot.data[
      "page"
    ] as LandingPageData;

    const numbers = this.route.snapshot.data["numbers"];
    this.meta = meta;
    this.heroSlides = page.data.heroSlides;
    this.staff = page.data.staff;
    this.numbers = numbers.slice(-4);    
    this.applyTitle = meta.applyTitle;
    this.applyDescription = meta.applyDescription;
    this.aboutMagazineDescription = meta.aboutMagazineDescription;
    this.aboutMagazineImage = meta.aboutMagazineImage;
    this.magazineNumbersImage = meta.magazineNumbersImage;
    AOS.init({
      // disable: "mobile"
      once: true,
    });
    AOS.refresh(); //refresh method is called on window resize and so on, as it doesn't require to build new store with AOS elements and should be as light as possible.
  }
}
