import { gql } from "apollo-angular";
import { EditingStaffPage } from "../interfaces/editing-staff-page";
import { EditingStaff } from "../interfaces/landing-page";

export const GET_EDITING_STAFF_PAGE = gql<
  { page: EditingStaffPage; editingStaff: EditingStaff[] },
  void
>`
  query {
    page: gulf_editing_staff {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    editingStaff: editing_staff {
      id
      name
      jobTitle: job_title
      jobDescription: job_description
      avatar {
        id
        filename: filename_download
        title
      }
      educationalPart: educational_part
      administrativeAndPracticalExperiences: administrative_and_practical_experiences
      worksAndTasksHighlights: works_and_tasks_highlights
      isManager: is_manager
    }
  }
`;

export const GET_SINGLE_STAFF_PAGE = gql<
  {
    page: EditingStaffPage;
    editingStaff: EditingStaff;
  },
  { staffId: string }
>`
  query ($staffId: ID!) {
    page: gulf_editing_staff {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    editingStaff: editing_staff_by_id(id: $staffId) {
      id
      name
      jobTitle: job_title
      jobDescription: job_description
      avatar {
        id
        filename: filename_download
        title
      }
      educationalPart: educational_part
      administrativeAndPracticalExperiences: administrative_and_practical_experiences
      worksAndTasksHighlights: works_and_tasks_highlights
      isManager: is_manager
    }
  }
`;
