<app-page>
  <page-header></page-header>
  <page-content>
    <div id="policies" class="my-5">
      <div class="container">
        <!-- researchApply -->
        <div class="policy-card">
          <div class="alert alert-warning text-dark p-4">
            <h6>تقديم البحث</h6>
            <div
              class="mt-4"
              [innerHTML]="proceduresStagesData.researchApply"
            ></div>
          </div>
        </div>

        <!-- conformityToConditionAndPreminaryExamination -->
        <div class="policy-card">
          <div class="alert alert-warning text-dark p-4">
            <h6>مطابقة الشروط والفحص الأولي</h6>
            <div
              class="mt-4"
              [innerHTML]="
                proceduresStagesData.conformityToConditionAndPreminaryExamination
              "
            ></div>
          </div>
        </div>
        <!-- arbitration -->
        <div class="policy-card">
          <div class="alert alert-warning text-dark p-4">
            <h6>التحكيم</h6>
            <div
              class="mt-4"
              [innerHTML]="proceduresStagesData.arbitration"
            ></div>
          </div>
        </div>

        <!-- modificationProcedures -->
        <div class="policy-card">
          <div class="alert alert-warning text-dark p-4">
            <h6>إجراءات التعديل</h6>
            <div
              class="mt-4"
              [innerHTML]="proceduresStagesData.modificationProcedures"
            ></div>
          </div>
        </div>

        <!-- acceptanceAndRejection -->
        <div class="policy-card">
          <div class="alert alert-warning text-dark p-4">
            <h6>القبول والرفض</h6>
            <div
              class="mt-4"
              [innerHTML]="proceduresStagesData.acceptanceAndRejection"
            ></div>
          </div>
        </div>

        <!-- auditAndPublication -->
        <div class="policy-card">
          <div class="alert alert-warning text-dark p-4">
            <h6>التدقيق والنشر</h6>
            <div
              class="mt-4"
              [innerHTML]="proceduresStagesData.auditAndPublication"
            ></div>
          </div>
        </div>
        <!-- معايير ومؤشرات مطابقة الشروط -->
        <h6 class="fw-bold mt-5 mb-3">معايير ومؤشرات مطابقة الشروط</h6>
        <!-- relevanceOfTheTopic -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">
                ملاءمة الموضوع
              </div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="standardsAndIndicators.relevanceOfTheTopic"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- size -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">الحجم</div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="standardsAndIndicators.size"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- address -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">العنوان</div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="standardsAndIndicators.address"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- abstract -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">المستخلص</div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="standardsAndIndicators.abstract"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- معايير ومؤشرات الفحص الأولي من هيئة التحرير -->

        <h6 class="fw-bold mt-5 mb-3">
          معايير ومؤشرات الفحص الأولي من هيئة التحرير
        </h6>
        <!-- relevanceOfTheTopic -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">
                ملاءمة الموضوع
              </div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="criteriaAndIndicators.relevanceOfTheTopic"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- introduction -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">المقدمة</div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="criteriaAndIndicators.introduction"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- problem -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">المشكلة</div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="criteriaAndIndicators.problem"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- methodology -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">المنهجية</div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="criteriaAndIndicators.methodology"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- references -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">المراجع</div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="criteriaAndIndicators.references"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <h6 class="fw-bold mt-5 mb-3">القواعد المتعلقة بالمضمون (التحكيم)</h6>

        <!-- address -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">العنوان</div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="contentRulesArbitration.address"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- abstract -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">المستخلص</div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="contentRulesArbitration.abstract"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- introductionAndStudyLiterature -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">
                المقدمة وأدبيات الدراسة
              </div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="
                    contentRulesArbitration.introductionAndStudyLiterature
                  "
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- problem -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">المشكلة</div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="contentRulesArbitration.problem"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- objectives -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">الأهداف</div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="contentRulesArbitration.objectives"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- questions -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">الأسئلة</div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="contentRulesArbitration.questions"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- importance -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">الأهمية</div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="contentRulesArbitration.importance"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <!-- limits -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">الحدود</div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="contentRulesArbitration.limits"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- terminology -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">
                المصطلحات
              </div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="contentRulesArbitration.terminology"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- methodology -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">المنهجية</div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="contentRulesArbitration.methodology"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- communityAndSample -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">
                المجتمع والعينة
              </div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="contentRulesArbitration.communityAndSample"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- theTool -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">الأداة</div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="contentRulesArbitration.theTool"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- resultsAndDiscussion -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">
                النتائج والمناقشة
              </div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="contentRulesArbitration.resultsAndDiscussion"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- conclusion -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">الخاتمة</div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="contentRulesArbitration.conclusion"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- recommendationsAndSuggestions -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">
                التوصيات والمقترحات
              </div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="
                    contentRulesArbitration.recommendationsAndSuggestions
                  "
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- documentationReferences -->
        <div class="terms-card">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="text-white bg-primary text-center py-3">
                التوثيق المراجع
              </div>
            </div>
            <div class="col">
              <div class="alert alert-success text-dark rounded-0">
                <div
                  [innerHTML]="contentRulesArbitration.documentationReferences"
                  class="px-3 mb-0"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
