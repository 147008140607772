import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  PreviousPublicationsPage,
  PreviousPublicationsPageData,
} from "src/app/interfaces/previous-publications-page";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-previous-publications",
  templateUrl: "./previous-publications.component.html",
  styleUrls: ["./previous-publications.component.scss"],
})
export class PreviousPublicationsComponent implements OnInit {
  currentPage = 1;
  totalPages!: number;
  pageSize = 50;
  keyword: string = "";
  publications: Publication[] = [];
  environment = environment;
  domains: Domain[] = [];
  loading!: boolean;
  totalPublicationsCount!: number;
  constructor(private route: ActivatedRoute, private http: HttpClient) {}
  previousPublicationsData!: PreviousPublicationsPage;
  ngOnInit() {
    const page = this.route.snapshot.data[
      "page"
    ] as PreviousPublicationsPageData;
    this.previousPublicationsData = page.meta;
    this.getPublications();
    this.getAllDomains();
  }

  getPublications(): void {
    this.loading = true;
    this.http
      .get<any[]>(
        `${environment.magazine_API}/api/publications?page=${this.currentPage}&pagesize=${this.pageSize}&keyword=${this.keyword}`
      )
      .subscribe((data: any) => {
        this.publications = data.data;
        this.totalPublicationsCount = data.totalCount;
        this.totalPages = Math.ceil(data.totalCount / this.pageSize);
        this.loading = false;
        window.scrollTo(0, 0);
      });
  }
  // كافة أنواع الأبحاث
  getAllDomains() {
    this.http
      .get<any[]>(`${environment.magazine_API}/api/domains`)
      .subscribe((data: any) => {
        this.domains = data.data;
      });
  }

  showItemsByPage(page: number) {
    this.currentPage = page;
    const listItems = document.getElementsByClassName("page-item");
    for (let i = 0; i < listItems.length; i++) {
      const item = listItems[i];
      if (page === i + 1) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    }
    this.getPublications();
  }

  searchForPublication(event: Event) {
    this.keyword = (event.target as HTMLInputElement).value.trim();
    this.currentPage = 1;
    this.getPublications();
  }
}

export interface Publication {
  date: string;
  domainName: string;
  id: string;
  issueName: string;
  researcher: string;
  subject: string;
  number: number;
  year:string;
}
export interface Domain {
  id: string;
  name: string;
  count: number;
}
