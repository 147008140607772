<section id="versions">
  <div class="container">
    <div class="patterns">
      <img
        class="top-right"
        alt=""
        src="assets/images/pattern-versions-top-right.svg"
      />
      <img
        class="top-left"
        alt=""
        src="assets/images/pattern-versions-top-left.svg"
      />
    </div>
    <h5 class="title text-primary">آخر أعداد المجلة</h5>
    <p class="subtitle">آخر أعداد المجلة</p>

    <div class="row">
      <div *ngFor="let item of numbers" class="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div
          routerLink="issues/{{ item.number }}"
          class="version-card cursor-pointer"
        >
          <div class="img-wrapper">
            <img
              [src]="magazineNumbersImage | imageLink"
              class=""
              [alt]="magazineNumbersImage.title"
            />
          </div>
          <h6 class="fw-bold text-dark text-center">{{ item.name }}</h6>
          <h6 class="fw-bold text-primary text-center">
            العدد {{ item.number }}
          </h6>
        </div>
      </div>
      <div class="text-center">
        <a routerLink="issues" class="btn btn-dark px-4">ارشيف الاعداد</a>
      </div>
    </div>
  </div>
</section>
