<app-page>
  <page-header></page-header>
  <page-content>
    <div id="commitees" class="my-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 mx-auto mb-4">
            <div class="committee-card card">
              <div class="card-body p-4">
                <img
                  class="committee-img"
                  style="width: 108px"
                  [src]="manager.avatar | imageLink"
                  [alt]="manager.avatar.title"
                />
                <div class="fs-6 text-center fw-bold">
                  <div class="committee-name mb-1">{{ manager.name }}</div>
                  <div class="committee-position">{{ manager.jobTitle }}</div>
                  <hr class="my-3 mx-3" />
                  <div class="committee-description">
                    {{ manager.jobDescription }}
                  </div>
                  <div class="d-flex justify-content-center m-4">
                    <!-- Email Section -->
                    <div class="committee-email">
                      <a
                        *ngIf="manager.email"
                        [href]="'mailto:' + manager.email"
                        class="text-primary text-decoration-none fw-normal"
                      >
                        <mat-icon title="البريد الإلكتروني">email</mat-icon>
                      </a>
                    </div>

                    <!-- Resume (CV) Section -->
                    <div class="committee-resume">
                      <a
                        *ngIf="manager.resumeUrl"
                        [href]="manager.resumeUrl"
                        target="_blank"
                        class="text-primary text-decoration-none fw-normal"
                      >
                        <mat-icon title="رابط السيرة الذاتية"
                          >assignment</mat-icon
                        >
                      </a>
                    </div>
                    <div class="committee-details">
                      <a
                        [routerLink]="manager.id"
                        target="_self"
                        class="text-primary text-decoration-none fw-normal"
                      >
                        <mat-icon title="المزيد من التفاصيل">info</mat-icon>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-7">
          <div
            *ngFor="let staff of editingStaff"
            class="col-lg-3 col-md-4 col-sm-6 mx-auto mb-4"
          >
            <div class="committee-card card">
              <div class="card-body p-4">
                <img
                  class="committee-img"
                  style="width: 108px"
                  [src]="staff.avatar | imageLink"
                  [alt]="staff.avatar.title"
                />
                <div class="fs-6 text-center fw-bold">
                  <div class="committee-name mb-1">{{ staff.name }}</div>
                  <div class="committee-position">{{ staff.jobTitle }}</div>
                  <hr class="my-3 mx-3" />
                  <div class="committee-description">
                    {{ staff.jobDescription }}
                  </div>
                  <div class="d-flex justify-content-center m-4">
                    <!-- Email Section -->
                    <div class="committee-email">
                      <a
                        *ngIf="staff.email"
                        [href]="'mailto:' + staff.email"
                        class="text-primary text-decoration-none fw-normal"
                      >
                        <mat-icon title="البريد الإلكتروني">email</mat-icon>
                      </a>
                    </div>

                    <!-- Resume (CV) Section -->
                    <div class="committee-resume">
                      <a
                        *ngIf="staff.resumeUrl"
                        [href]="staff.resumeUrl"
                        target="_blank"
                        class="text-primary text-decoration-none fw-normal"
                      >
                        <mat-icon title="رابط السيرة الذاتية"
                          >assignment</mat-icon
                        >
                      </a>
                    </div>
                    <div class="committee-details">
                      <a
                        [routerLink]="staff.id"
                        target="_self"
                        class="text-primary text-decoration-none fw-normal"
                      >
                        <mat-icon title="المزيد من التفاصيل">info</mat-icon>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
