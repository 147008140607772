<app-page>
  <page-header></page-header>
  <page-content>
    <div id="commitees" class="my-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 mx-auto mb-4">
            <a [routerLink]="manager.id" target="_self">
              <div class="committee-card card">
                <div class="card-body p-4">
                  <img
                    class="committee-img"
                    style="width: 108px"
                    [src]="manager.avatar | imageLink"
                    [alt]="manager.avatar.title"
                  />
                  <div class="fs-6 text-center fw-bold">
                    <div class="committee-name mb-1">{{ manager.name }}</div>
                    <div class="committee-position">{{ manager.jobTitle }}</div>
                    <hr class="my-3 mx-3" />
                    <div class="committee-description">
                      {{ manager.jobDescription }}
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="row mt-7">
          <div
            *ngFor="let staff of editingStaff"
            class="col-lg-3 col-md-4 col-sm-6 mx-auto mb-4"
          >
            <a [routerLink]="staff.id" target="_self">
              <div class="committee-card card">
                <div class="card-body p-4">
                  <img
                    class="committee-img"
                    style="width: 108px"
                    [src]="staff.avatar | imageLink"
                    [alt]="staff.avatar.title"
                  />
                  <div class="fs-6 text-center fw-bold">
                    <div class="committee-name mb-1">{{ staff.name }}</div>
                    <div class="committee-position">{{ staff.jobTitle }}</div>
                    <hr class="my-3 mx-3" />
                    <div class="committee-description">
                      {{ staff.jobDescription }}
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
