import { Component } from "@angular/core";

@Component({
  selector: "app-landing-publishing-conditions",
  templateUrl: "./landing-publishing-conditions.component.html",
  styleUrls: ["./landing-publishing-conditions.component.scss"],
})
export class LandingPublishingConditionsComponent {
  terms = [
    {
      id: 1,
      title: "أهداف ومجالات النشر",
      link: "publishing-fields-goals",
      icon: "assets/images/publishing-goals.svg",
    },
    {
      id: 2,
      title: "شروط النشر",
      link: "publishing-terms",
      icon: "assets/images/publishing-conditions.svg",
    },
    {
      id: 3,
      title: "الإجراءات والمراحل",
      link: "procedures-stages",
      icon: "assets/images/publishing-fields.svg",
    },
    {
      id: 4,
      title: "سياسة وأخلاقيات النشر",
      link: "policy-and-ethics",
      icon: "assets/images/politics-and-targets.svg",
    },
  ];
}
