<app-page>
  <page-header></page-header>
  <page-content>
    <div id="about-us" class="my-5">
      <div class="container">
        <div class="row">
          <div class="col-md-9 mt-4">
            <div class="row">
              <div class="col-md-3 mb-5 mb-md-0">
                <img
                  class="rounded-3 d-block w-100 img-fluid cover"
                  style="aspect-ratio: 1"
                  [src]="aboutUsData.magazineImage | imageLink"
                  [alt]="aboutUsData.magazineImage.title"
                />
              </div>
              <div class="col">
                <h6 class="fw-bold">{{ aboutUsData.magazineTitle }}</h6>
                <p
                  [innerHTML]="aboutUsData.description"
                  class="fw-light pe-md-6 mt-4"
                ></p>
              </div>
            </div>
          </div>
          <div class="col mt-4">
            <div class="bg-gold text-light p-4 rounded-3">
              <h6 class="fw-bold mb-3">الرقم المعياري الدولي (ISSN):</h6>
              <p [innerHTML]="issn.issn" class="small mb-0"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
