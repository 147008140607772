import { Component, Input } from "@angular/core";
import { ImageFile } from "src/app/interfaces/image-file";

@Component({
  selector: "app-landing-about-news",
  templateUrl: "./landing-about-news.component.html",
  styleUrls: ["./landing-about-news.component.scss"],
})
export class LandingAboutNewsComponent {
  @Input() aboutMagazineImage!: ImageFile;
  @Input() aboutMagazineDescription!: string;
}
