import { Component, Input } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-landing-researches-apply",
  templateUrl: "./landing-researches-apply.component.html",
  styleUrls: ["./landing-researches-apply.component.scss"],
})
export class LandingResearchesApplyComponent {
  @Input() applyTitle!: string;
  @Input() applyDescription!: string;
  magazineSystemURL = environment.magazineSystemURL;
}
