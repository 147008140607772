<div class="bg-dark text-light py-5">
  <div class="container">
    <div class="row mt-5">
      <div class="col-lg-8 col-lg-6">
        <div
          class="d-flex d-md-block flex-column align-items-center justify-content-center"
        >
          <h6 class="text-primary fw-bold footer-header pb-4">
            مكتب التربية العربي لدول الخليج
          </h6>
          <hr
            style="width: 28ch"
            class="border border-primary border-1 opacity-75"
          />
        </div>

        <ul class="footer-links list-unstyled mb-5 mt-5 mx-3 mx-md-0">
          <li
            *ngFor="let link of footerLinks"
            class="list-unstyled-item ps-2 link-height"
          >
            <a
              style="font-size: var(--footer-links-fontSize)"
              [routerLink]="link.href"
              >{{ link.title }}</a
            >
          </li>
        </ul>
      </div>
      <div *ngIf="contact" class="col">
        <div
          class="d-flex d-md-block flex-column justify-content-center align-items-center"
        >
          <h6 class="text-primary fw-bold footer-header pb-4">تواصل معنا</h6>
          <hr
            style="width: 12ch"
            class="border border-primary border-1 opacity-75"
          />
        </div>

        <div class="mt-5 text-light">
          <p
            style="font-size: var(--footer-links-fontSize)"
            class="link-height mb-0"
          >
            <i class="fa-solid fa-location-dot me-3"></i>
            {{ contact.location }}
          </p>

          <p class="link-height mb-0">
            <i class="fa-solid fa-phone fa-flip-horizontal me-3"></i>
            <a
              style="font-size: var(--footer-links-fontSize)"
              class="text-light"
              href="tel: +966112664470"
            >
              {{ contact.phone }}
            </a>
          </p>

          <p class="link-height mb-0">
            <i class="fa-solid fa-envelope fa-flip-horizontal me-3"></i>
            <a
              style="font-size: var(--footer-links-fontSize)"
              class="text-light"
              href="mailto: journal@abegs.org"
            >
              {{ contact.email }}
            </a>
          </p>
          <p
            style="font-size: var(--footer-links-fontSize)"
            class="link-height mb-0"
          >
            <i class="fa-solid fa-question fa-flip-horizontal me-3"></i>
            هل لديك أي أسئلة تود طرحها؟
          </p>

          <a
            href="mailto: journal@abegs.org"
            target="_blank"
            class="btn btn-primary fw-bold mt-4"
            >تواصل معنا</a
          >

          <div
            id="social-media"
            class="social-media-links mt-4 d-flex justify-content-center justify-content-md-start align-content-center gap-4"
          >
            <a
              class="text-light"
              target="_blank"
              href="https://www.youtube.com/@AbegsOrg"
            >
              <i class="fa-brands fa-youtube"></i>
            </a>
            <a
              class="text-light"
              target="_blank"
              href="https://www.instagram.com/_abegsorg/"
            >
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a
              class="text-light"
              target="_blank"
              href="https://www.facebook.com/abegsorg/"
            >
              <i class="fa-brands fa-facebook"></i>
            </a>
            <a
              class="text-light"
              target="_blank"
              href="https://x.com/abegsorg"
            >
              <i class="fa-brands fa-x-twitter"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button
  #scrollTopBtn
  routerLink="."
  class="btn btn-primary rounded-circle scroll-top-btn shadow"
>
  <i class="fa-solid fa-chevron-up"></i>
</button>
