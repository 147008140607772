import { gql } from "apollo-angular";
import { publishingPolicyAndEthicsPage } from "../interfaces/publishing-policy-and-ethics-page";

export const GET_PUBLISHING_POLICY_AND_ETHICS_PAGE = gql<
  { page: publishingPolicyAndEthicsPage },
  void
>`
  query {
    page: gulf_publishing_policy_and_ethics {
      title
      image {
        id
        filename: filename_download
        title
      }
      publishingPolicyImage: publishing_policy_image {
        id
        filename: filename_download
        title
      }
      publishingPolicy: publishing_policy
      publicationEthics: publication_ethics
      quotationMeasure: quotation_measure
    }
  }
`;
