import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SingleStaffPageData } from "src/app/interfaces/editing-staff-page";
import { EditingStaff } from "src/app/interfaces/landing-page";

@Component({
  selector: "app-staff",
  templateUrl: "./staff.component.html",
  styleUrls: ["./staff.component.scss"],
})
export class StaffComponent implements OnInit {
  title = "";
  editingStaff!: EditingStaff;
  constructor(private route: ActivatedRoute) {}
  ngOnInit(): void {
    const { meta, ...page } = this.route.snapshot.data[
      "page"
    ] as SingleStaffPageData;
    this.title = meta.title;
    this.editingStaff = page.data.editingStaff;
  }
}