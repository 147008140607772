import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Issn } from "src/app/interfaces/issn";
import {
   PublishingGoalsFieldsPage,
  PublishingGoalsFieldsPageData,
} from "src/app/interfaces/publishing-goals-fields-page";

@Component({
  selector: "app-publishing-fields-goals",
  templateUrl: "./publishing-fields-goals.component.html",
  styleUrls: ["./publishing-fields-goals.component.scss"],
})
export class PublishingFieldsGoalsComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  publishingGoalsFields!: PublishingGoalsFieldsPage;
  issn!: Issn;
  ngOnInit(): void {
    const page = this.route.snapshot.data[
      "page"
    ] as PublishingGoalsFieldsPageData;
    this.publishingGoalsFields = page.meta;
     this.issn = page.data.issn;
  }
}
