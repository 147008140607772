<app-page>
  <page-header></page-header>
  <page-content>
    <div id="single-commitee" class="my-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-9 col-sm-10 mx-auto mb-4">
            <div class="card shadow-lg">
              <div class="card-body p-5">
                <img
                  class="committee-img"
                  style="width: 108px"
                  [src]="editingStaff.avatar | imageLink"
                  [alt]="editingStaff.avatar.title"
                />
                <div class="fs-6 text-center fw-bold">
                  <div class="committee-name mb-1 text-gold">
                    {{ editingStaff.name }}
                  </div>
                  <div class="committee-position">{{ editingStaff.jobTitle }}</div>
                </div>

                <section class="mt-5">
                  <h6 class="fw-bold section-heading">الجانب التعليمي</h6>
                  <div
                    [innerHTML]="editingStaff.educationalPart"
                    class="element mt-3"
                  ></div>
                </section>

                <section class="mt-5">
                  <h6 class="fw-bold section-heading">
                    الخبرات الإدارية والعملية
                  </h6>
                  <div
                    [innerHTML]="
                      editingStaff.administrativeAndPracticalExperiences
                    "
                    class="element mt-3"
                  ></div>
                </section>

                <section class="mt-5">
                  <h6 class="fw-bold section-heading">أبرز الأعمال والمهام</h6>
                  <div
                    [innerHTML]="editingStaff.worksAndTasksHighlights"
                    class="element mt-3"
                  ></div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
