import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable, of } from "rxjs";
import { ProceduresStagesPageData } from "../interfaces/procedures-stages-page";
import { GET_PROCEDURES_STAGES_PAGE_DATA } from "../queries/procedures-stages-page.query";

@Injectable({
  providedIn: "root",
})
export class ProceduresStagesPageResolver
  implements Resolve<ProceduresStagesPageData>
{
  constructor(private apollo: Apollo) {}

  resolve(): Observable<ProceduresStagesPageData> {
    return this.apollo.query({ query: GET_PROCEDURES_STAGES_PAGE_DATA }).pipe(
      map((result): ProceduresStagesPageData => {
        const { page: meta, ...page } = result.data;
        return {
          title: "الاجراءات والمراحل",
          hero: {
            title: meta.title,
            image: meta.image,
          },
          data: page,
          meta,
        };
      })
    );
  }
}