import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable } from "rxjs";
import { PreviousPublicationsPageData } from "../interfaces/previous-publications-page";
import { GET_PREVIOUS_PUBLICATIONS_PAGE } from "../queries/previous-publications-page.query";

@Injectable({
  providedIn: "root",
})
export class PreviousPublicationsPageResolver
  implements Resolve<PreviousPublicationsPageData>
{
  constructor(private apollo: Apollo) {}

  resolve(): Observable<PreviousPublicationsPageData> {
    return this.apollo
      .query({
        query: GET_PREVIOUS_PUBLICATIONS_PAGE,
      })
      .pipe(
        map((result): PreviousPublicationsPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: "البحوث السابقة",
            hero: {
              title: meta.title,
              image: meta.image,
            },
            data: page,
            meta,
          };
        })
      );
  }
}
