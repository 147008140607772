import { gql } from "apollo-angular";
import { PreviousIssuesPage } from "../interfaces/previous-issues-page";
import { ImageFile } from "../interfaces/image-file";

export const GET_PREVIOUS_ISSUES_PAGE = gql<
  { page: PreviousIssuesPage; homePage: { numsImage: ImageFile } },
  void
>`
  query {
    page: gulf_previous_issues {
      title
      image {
        id
        filename: filename_download
        title
      }
    }

    homePage: gulf_home_page {
      numsImage: magazine_numbers_image {
        id
        filename: filename_download
        title
      }
    }
  }
`;
