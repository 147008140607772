<section id="team">
  <div class="container">
    <p class="fw-bold text-primary text-center">هيئة التحرير</p>
    <h4 class="fw-bold mb-4 text-center">فريق عمل المجلة</h4>

    <div flickity [flickityConfig]="flickityConfig" class="slider">
      <div *ngFor="let singleStaff of staff" class="single-slide">
        <div class="committee-card card">
          <div class="card-body p-4">
            <img
              class="committee-img"
              style="width: 108px"
              [src]="singleStaff.avatar | imageLink"
              [alt]="singleStaff.avatar.title"
            />
            <div class="fs-6 text-center fw-bold">
              <div class="committee-name mb-1">{{ singleStaff.name }}</div>
              <div class="committee-position">{{ singleStaff.jobTitle }}</div>
              <hr class="my-3 mx-3" />
              <div class="committee-description">
                {{ singleStaff.jobDescription }}
              </div>
              <div class="d-flex justify-content-center m-4">
                <!-- Email Section -->
                <div class="committee-email">
                  <a
                  *ngIf="singleStaff.email"
                  [href]="'mailto:' + singleStaff.email"
                  class="text-primary text-decoration-none fw-normal"
                  >
                  <mat-icon title="البريد الإلكتروني">email</mat-icon>
                  </a>
                </div>

                <!-- Resume (CV) Section -->
                <div class="committee-resume">
                  <a
                  *ngIf="singleStaff.resumeUrl"
                  [href]="singleStaff.resumeUrl"
                  target="_blank"
                  class="text-primary text-decoration-none fw-normal"
                  >
                  <mat-icon title="رابط السيرة الذاتية">assignment</mat-icon>
                  </a>
                </div>
                <div class="committee-details">
                  <a
                    routerLink="committees/{{ singleStaff.id }}"
                    target="_self"
                    class="text-primary text-decoration-none fw-normal"
                  >
                    <mat-icon title="المزيد من التفاصيل">info</mat-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
