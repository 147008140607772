<app-page>
  <page-header></page-header>
  <page-content>
    <div id="researches" class="my-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-md-8">
            <form class="mb-3" action="">
              <div class="search-form input-with-icon">
                <i class="fa-solid fa-magnifying-glass"></i>
                <input
                  type="text"
                  class="form-control"
                  (input)="searchForPublication($event)"
                  placeholder="الــبــحـــث"
                />
              </div>
            </form>

            <h5 class="">
              <i class="fa-solid fa-file-signature me-3 text-primary"></i>
              البحوث
            </h5>
            <hr class="mb-4" />

            <div class="table-responsive">
              <table class="table table-lg table-bordered table-striped">
                <thead>
                  <tr class="bg-very-light-gray text-center">
                    <th class="p-3" scope="col" width="100px">العدد</th>
                    <th class="p-3" scope="col">عنوان البحث</th>
                    <th class="p-3" scope="col" width="30%">اسم الباحث</th>
                    <th class="p-3" scope="col" width="150px">تحميل البحث</th>
                  </tr>
                </thead>
                <tbody class="small">
                  <tr *ngFor="let publication of publications; index as j">
                    <td class="text-center">{{ publication.number }}</td>
                    <td class=" ">
                      {{ publication.subject }}
                    </td>
                    <td class=" ">{{ publication.researcher }}</td>
                    <td class="text-center text-primary">
                      <a
                        href="{{ environment.magazine_API }}/assets/{{
                          publication.id
                        }}"
                      >
                        <i class="fa-solid fa-file-arrow-down fs-3"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="mt-5">
              <!-- start pagination -->
              <nav *ngIf="totalPages > 0">
                <ul class="pagination justify-content-center dark d-flex" style="flex-wrap: wrap">
                  <li
                    *ngFor="let item of [].constructor(totalPages); index as i"
                    (click)="showItemsByPage(i + 1)"
                    class="page-item"
                    [ngClass]="{ active: currentPage === i + 1 }"
                  >
                    <a class="page-link">{{ i + 1 }}</a>
                  </li>
                </ul>
              </nav>
              <div *ngIf="publications?.length === 0" class="no-data">
                لا توجد أبحاث
              </div>

              <!-- end pagination  -->
            </div>
          </div>
          <div class="col">
            <ul class="list-group">
              <li
                class="list-group-item bg-primary text-white text-center fs-5 fw-bold"
              >
                مجالات البحوث
              </li>
              <li
                class="list-group-item bg-dark text-white d-flex justify-content-between align-items-center"
              >
                <div class="text-white">كافة الفئات</div>
                <span class="badge bg-white text-dark">{{
                  totalPublicationsCount
                }}</span>
              </li>

              <a
                *ngFor="let domain of domains; index as i"
                class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
              >
                <div class="">{{ domain.name }}</div>
                <span class="badge bg-very-light-gray text-dark">{{
                  domain.count
                }}</span>
              </a>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
