<app-navbar></app-navbar>
<div id="home">
  <app-landing-hero [heroSlides]="heroSlides"></app-landing-hero>
  <app-landing-news-latest-numbers
    [numbers]="numbers"
    [magazineNumbersImage]="magazineNumbersImage"
  ></app-landing-news-latest-numbers>
  <app-landing-about-news
    [aboutMagazineImage]="aboutMagazineImage"
    [aboutMagazineDescription]="aboutMagazineDescription"
  ></app-landing-about-news>
  <app-landing-publishing-conditions></app-landing-publishing-conditions>
  <app-landing-researches-apply
    [applyTitle]="applyTitle"
    [applyDescription]="applyDescription"
  ></app-landing-researches-apply>
  <app-landing-editing-staff [staff]="staff"></app-landing-editing-staff>
</div>
<app-footer-links></app-footer-links>
<app-copyright></app-copyright>
