import { Component, Input, OnInit } from "@angular/core";
import { ImageFile } from "src/app/interfaces/image-file";
import { LandingMagazineNumbers } from "src/app/interfaces/landing-page";

@Component({
  selector: "app-landing-news-latest-numbers",
  templateUrl: "./landing-news-latest-numbers.component.html",
  styleUrls: ["./landing-news-latest-numbers.component.scss"],
})
export class LandingNewsLatestNumbersComponent {
  @Input() numbers!: LandingMagazineNumbers[];
  @Input() magazineNumbersImage!: ImageFile;
}
