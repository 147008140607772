import { gql } from "apollo-angular";
import { AboutUsPage } from "../interfaces/about-us-page";
import { Issn } from "../interfaces/issn";

export const GET_ABOUT_US_PAGE = gql<{ page: AboutUsPage; issn: Issn }, void>`
  query {
    page: gulf_about_us {
      title
      image {
        id
        filename: filename_download
        title
      }
      magazineTitle: magazine_title
      description
      magazineImage: magazine_image {
        id
        filename: filename_download
        title
      }
    }
    issn: gulf_magazine_issn {
      issn
    }
  }
`;
