import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  publishingPolicyAndEthicsPage,
  publishingPolicyAndEthicsPageData,
} from "src/app/interfaces/publishing-policy-and-ethics-page";

@Component({
  selector: "app-publishing-policy-and-ethics",
  templateUrl: "./publishing-policy-and-ethics.component.html",
  styleUrls: ["./publishing-policy-and-ethics.component.scss"],
})
export class PublishingPolicyAndEthicsComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  publishingPolicyAndEthicsData!: publishingPolicyAndEthicsPage;
  ngOnInit(): void {
    const page = this.route.snapshot.data[
      "page"
    ] as publishingPolicyAndEthicsPageData;
    this.publishingPolicyAndEthicsData = page.meta;
  }
}
