import { gql } from "apollo-angular";
import {
  LandingPage,
  EditingStaff,
  LandingHeroSlide,
} from "../interfaces/landing-page";

export const GET_LANDING_PAGE_DATA = gql<
  {
    page: LandingPage;
    heroSlides: LandingHeroSlide[];
    staff: EditingStaff[];
  },
  void
>`
  query {
    page: gulf_home_page {
      magazineNumbersImage: magazine_numbers_image {
        id
        filename: filename_download
        title
      }
      aboutMagazineDescription: about_magazine_description
      aboutMagazineImage: about_magazine_image {
        id
        filename: filename_download
        title
      }
      applyTitle: apply_title
      applyDescription: apply_description
    }

    heroSlides: gulf_home_slider {
      id
      title
      subTitle: sub_title
      image {
        id
        filename: filename_download
        title
      }
    }

    staff: editing_staff(filter: {status: {_eq: "published"}}) {
      id
      name
      jobTitle: job_title
      email
      resumeUrl:resume_url
      jobDescription: job_description
      avatar {
        id
        filename: filename_download
        title
      }
    }
  }
`;
