<app-page>
  <page-header></page-header>
  <page-content>
    <div
      *ngIf="editingStaff?.id; else noData"
      id="single-commitee"
      class="my-5"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-9 col-sm-10 mx-auto mb-4">
            <div class="card shadow-lg">
              <div class="card-body p-5">
                <img
                  class="committee-img"
                  style="width: 108px"
                  [src]="editingStaff.avatar | imageLink"
                  [alt]="editingStaff.avatar.title"
                />
                <div class="fs-6 text-center fw-bold">
                  <div class="committee-name mb-1 text-gold">
                    {{ editingStaff.name }}
                  </div>
                  <div class="committee-position">
                    {{ editingStaff.jobTitle }}
                  </div>
                </div>

                <section class="mt-5">
                  <h6 class="fw-bold section-heading">الجانب التعليمي</h6>
                  <div
                    [innerHTML]="editingStaff.educationalPart"
                    class="element mt-3"
                  ></div>
                </section>

                <section class="mt-5">
                  <h6 class="fw-bold section-heading">
                    الخبرات الإدارية والعملية
                  </h6>
                  <div
                    [innerHTML]="
                      editingStaff.administrativeAndPracticalExperiences
                    "
                    class="element mt-3"
                  ></div>
                </section>

                <section class="mt-5">
                  <h6 class="fw-bold section-heading">أبرز الأعمال والمهام</h6>
                  <div
                    [innerHTML]="editingStaff.worksAndTasksHighlights"
                    class="element mt-3"
                  ></div>
                </section>

                <section class="mt-5">
                  <h6 class="fw-bold section-heading">البريد الإلكتروني</h6>
                  <!-- <div
                    [innerHTML]="editingStaff.email"
                    class="element mt-3"
                  ></div> -->
                  <a 
                    *ngIf="editingStaff.email"
                    [href]="'mailto:' + editingStaff.email"
                    class="d-flex gap-1 text-primary text-decoration-none fw-normal"
                  >
                    <mat-icon title="البريد الإلكتروني">email</mat-icon>
                    {{editingStaff.email}}
                  </a>
                </section>

                <section class="mt-5">
                  <h6 class="fw-bold section-heading">رابط السيرة الذاتية</h6>
                  <!-- <div
                    [innerHTML]="editingStaff.resumeUrl"
                    class="element mt-3"
                  ></div> -->
                  <a
                  *ngIf="editingStaff.resumeUrl"
                  [href]="editingStaff.resumeUrl"
                  target="_blank"
                  class="d-flex gap-1 text-primary text-decoration-none fw-normal"
                  >
                  <mat-icon title="رابط السيرة الذاتية">assignment</mat-icon>
                  {{editingStaff.resumeUrl}}
                  </a>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noData>
      <div
        class="text-center text-muted p-9"
        style="height: 500px; width: 100%"
      >
        لا توجد بيانات
      </div>
    </ng-template>
  </page-content>
  <page-footer></page-footer>
</app-page>
