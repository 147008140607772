import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { map, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LandingPageMagazineNumbersResolver implements Resolve<any> {
  constructor(private http: HttpClient) {}

  resolve(): Observable<any> {
    return this.http.get<any>(`${environment.magazine_API}/api/issues`).pipe(
      map((data) => {
        return data.data.slice(0, 4);
      })
    );
  }
}
