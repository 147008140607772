import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  ContentRulesArbitration,
  CriteriaAndIndicators,
  ProceduresStagesPage,
  ProceduresStagesPageData,
  StandardsAndIndicators,
} from "src/app/interfaces/procedures-stages-page";

@Component({
  selector: "app-procedures-stages",
  templateUrl: "./procedures-stages.component.html",
  styleUrls: ["./procedures-stages.component.scss"],
})
export class ProceduresStagesComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  proceduresStagesData!: ProceduresStagesPage;
  standardsAndIndicators!: StandardsAndIndicators;
  criteriaAndIndicators!: CriteriaAndIndicators;
  contentRulesArbitration!: ContentRulesArbitration;

  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as ProceduresStagesPageData;
    this.proceduresStagesData = page.meta;
    this.standardsAndIndicators = page.data.standardsAndIndicators;
    this.criteriaAndIndicators = page.data.criteriaAndIndicators;
    this.contentRulesArbitration = page.data.contentRulesArbitration;
  }
}
