import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable, map } from "rxjs";
import { ContactUsPage } from "../interfaces/contact-us-page";

import { GET_CONTACT_US_PAGE } from "../queries/contact-us-page.query";

import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class CMSService {
  constructor(private apollo: Apollo, private httpClient: HttpClient) {}

  getContactUsDetails(): Observable<ContactUsPage> {
    return this.apollo
      .query({
        query: GET_CONTACT_US_PAGE,
      })
      .pipe(
        map((result): ContactUsPage => {
          const contactus = result.data.page;
          return contactus;
        })
      );
  }
}