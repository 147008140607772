import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable, of } from "rxjs";
import { PublishingGoalsFieldsPageData } from "../interfaces/publishing-goals-fields-page";
import { GET_PUBLISHING_GOALS_FIELDS_PAGE_DATA } from "../queries/publishing-goals-fields-page.query";

@Injectable({
  providedIn: "root",
})
export class PublishingGoalsFieldsPageResolver
  implements Resolve<PublishingGoalsFieldsPageData>
{
  constructor(private apollo: Apollo) {}

  resolve(): Observable<PublishingGoalsFieldsPageData> {
    return this.apollo
      .query({
        query: GET_PUBLISHING_GOALS_FIELDS_PAGE_DATA,
      })
      .pipe(
        map((result): PublishingGoalsFieldsPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: "أهداف ومجالات النشر",
            hero: {
              title: meta.title,
              image: meta.image,
            },
            data: page,
            meta,
          };
        })
      );
  }
}
