import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AboutUsPage, AboutUsPageData } from "src/app/interfaces/about-us-page";
import { Issn } from "src/app/interfaces/issn";

@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.scss"],
})
export class AboutUsComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  aboutUsData!: AboutUsPage;
  issn!: Issn;
  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as AboutUsPageData;
    this.aboutUsData = page.meta;
    this.issn = page.data.issn;
  }
}
