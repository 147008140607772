<div id="home-hero">
  <div flickity [flickityConfig]="flickityConfig" class="slider">
    <div
      *ngFor="let slide of heroSlides"
      class="hero-slide"
      style="
        background-image: url('{{slide.image|imageLink}}');
      "
    >
      <div class="container">
        <div class="captions">
          <div class="text1">{{slide.title}}</div>
          <div class="text2">
            {{slide.subTitle}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
