<app-page>
  <page-header></page-header>
  <page-content>
    <div id="terms" class="my-5">
      <div class="container">
        <div class="row">
          <div class="col-md-3 mb-5 mb-md-0">
            <img
              class="rounded-3 d-block w-100 img-fluid cover"
              style="aspect-ratio: 1"
              [src]="
                publishingPolicyAndEthicsData.publishingPolicyImage | imageLink
              "
              [alt]="publishingPolicyAndEthicsData.publishingPolicyImage.title"
            />
          </div>
          <div class="col">
            <div class="my-5"></div>

            <section class="my-5">
              <h5 class="fw-bold section-heading">سياسة النشر:</h5>
              <div
                [innerHTML]="publishingPolicyAndEthicsData.publishingPolicy"
                class=""
              ></div>
            </section>
            <section class="my-5">
              <h5 class="fw-bold section-heading">أخلاقيات النشر:</h5>
              <div
                [innerHTML]="publishingPolicyAndEthicsData.publicationEthics"
                class=""
              ></div>
            </section>

            <section class="my-5">
              <h5 class="fw-bold section-heading">قياس الاقتباس:</h5>
              <br />
              <div
                [innerHTML]="publishingPolicyAndEthicsData.quotationMeasure"
                class="px-4"
              ></div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
