import { gql } from "apollo-angular";
import { IssueResearchesPage } from "../interfaces/issue-researches-page";
import { ImageFile } from "../interfaces/image-file";

export const GET_ISSUE_RESEARCHES_PAGE = gql<
  { page: IssueResearchesPage; homePage: { numsImage: ImageFile } },
  void
>`
  query {
    page: gulf_previous_issues_details {
      title
      image {
        id
        filename: filename_download
        title
      }
    }

    homePage: gulf_home_page {
      numsImage: magazine_numbers_image {
        id
        filename: filename_download
        title
      }
    }
  }
`;
