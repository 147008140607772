import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  PublishingTermsPage,
  PublishingTermsPageData,
} from "src/app/interfaces/publishing-terms-page";

@Component({
  selector: "app-publishing-terms",
  templateUrl: "./publishing-terms.component.html",
  styleUrls: ["./publishing-terms.component.scss"],
})
export class PublishingTermsComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  publishingTermsData!: PublishingTermsPage;
  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as PublishingTermsPageData;
    this.publishingTermsData = page.meta;
  }
}
