import { gql } from "apollo-angular";
import {
  ContentRulesArbitration,
  CriteriaAndIndicators,
  ProceduresStagesPage,
  StandardsAndIndicators,
} from "../interfaces/procedures-stages-page";

export const GET_PROCEDURES_STAGES_PAGE_DATA = gql<
  {
    page: ProceduresStagesPage;
    standardsAndIndicators: StandardsAndIndicators;
    criteriaAndIndicators: CriteriaAndIndicators;
    contentRulesArbitration: ContentRulesArbitration;
  },
  void
>`
  query {
    page: gulf_procedures_and_stages {
      title
      image {
        id
        filename: filename_download
        title
      }
      researchApply: research_apply
      conformityToConditionAndPreminaryExamination: conformity_to_condition_and_preminary_examination
      arbitration
      modificationProcedures: modification_procedures
      acceptanceAndRejection: acceptance_and_rejection
      auditAndPublication: audit_and_publication
    }

    standardsAndIndicators: gulf_standards_and_indicators {
      id
      relevanceOfTheTopic: relevance_of_the_topic
      size
      address
      abstract
    }
    criteriaAndIndicators: gulf_criteria_and_indicators {
      id
      relevanceOfTheTopic: relevance_of_the_topic
      introduction
      problem
      methodology
      references
    }
    contentRulesArbitration:gulf_content_rules_arbitration{
      address
      abstract
      introductionAndStudyLiterature:introduction_and_study_literature
      problem
      objectives
      questions
      importance
      limits
      terminology
      methodology
      communityAndSample:community_and_sample
      theTool:the_tool
      resultsAndDiscussion:results_and_discussion
      conclusion
      recommendationsAndSuggestions:recommendations_and_suggestions
      documentationReferences:documentation_References
    }
  }
`;