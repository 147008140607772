import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable, of } from "rxjs";
import { PublishingTermsPageData } from "../interfaces/publishing-terms-page";
import { GET_PUBLISHING_TERMS_PAGE } from "../queries/publishing-terms-page.query";

@Injectable({
  providedIn: "root",
})
export class PublishingTermsPageResolver
  implements Resolve<PublishingTermsPageData>
{
  constructor(private apollo: Apollo) {}

  resolve(): Observable<PublishingTermsPageData> {
    return this.apollo
      .query({
        query: GET_PUBLISHING_TERMS_PAGE,
      })
      .pipe(
        map((result): PublishingTermsPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: "شروط النشر",
            hero: {
              title: meta.title,
              image: meta.image,
            },
            data: page,
            meta,
          };
        })
      );
  }
}
