import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, map } from "rxjs";
import { GET_ISSUE_RESEARCHES_PAGE } from "../queries/issue-researches-page.query";
import { IssueResearchesPageData } from "../interfaces/issue-researches-page";
import { Apollo } from "apollo-angular";

@Injectable({
  providedIn: "root",
})
export class IssueResearchesPageResolver
  implements Resolve<IssueResearchesPageData>
{
  constructor(private apollo: Apollo) {}

  resolve(): Observable<IssueResearchesPageData> {
    return this.apollo
      .query({
        query: GET_ISSUE_RESEARCHES_PAGE,
      })
      .pipe(
        map((result): IssueResearchesPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: "الأعداد السابقة",
            hero: {
              title: meta.title,
              image: meta.image,
            },
            data: page,
            meta,
          };
        })
      );
  }
}
