import { gql } from "apollo-angular";
import { PublishingTermsPage } from "../interfaces/publishing-terms-page";

export const GET_PUBLISHING_TERMS_PAGE = gql<
  { page: PublishingTermsPage },
  void
>`
  query {
    page: gulf_publishing_terms {
      title
      image {
        id
        filename: filename_download
        title
      }
      publishingTermsImage: publishing_terms_image {
        id
        filename: filename_download
        title
      }
      language
      termsAndRules: terms_and_rules
      docsAndReferences: docs_and_references
      proceduresAndStages: procedures_and_stages
    }
  }
`;
