<app-page>
  <page-header></page-header>
  <page-content>
    <div id="domains" class="my-5">
      <div class="container">
        <div class="row">
          <div *ngIf="issue" class="col-lg-3 col-md-4 mb-5 mb-md-0">
            <div class="version-card">
              <div class="img-wrapper">
                <img [src]="numsImage | imageLink" class="" alt="" />
              </div>
              <h6 class="fw-bold text-dark text-center">رسالة الخليج العربي</h6>
              <h6 class="fw-bold text-primary text-center">
                العدد {{ issueNumber }}
              </h6>
              <div *ngIf="issue.hasFile" class="text-center">
                <a
                  href="{{ environment.magazine_API }}/assets/issue/{{
                    issue.id
                  }}"
                  class="btn btn-dark text-dark fw-bold bg-transparent"
                >
                  <img
                    src="assets/images/file-pdf 2.svg"
                    style="width: 17px; height: 17px"
                    class="me-2"
                    alt=""
                  />
                  استعراض العدد
                </a>
              </div>
            </div>
          </div>

          <div *ngIf="issue" class="col">
            <div
              class="d-flex justify-content-between bg-primary text-white fw-bold rounded-2 px-4 py-3 mb-4"
            >
              <p class="mb-0">
                العدد رقم {{ issueNumber }} من رسالة الخليج العربي
              </p>
              <p class="mb-0">السنة : {{ issue.year }}</p>
              <p class="mb-0">تاريخ النشر : {{ issue.date }}</p>
            </div>

            <div class="table-responsive">
              <table
                class="table table-bordered fw-bold rounded-3 overflow-hidden"
              >
                <tr class="bg-dark text-white">
                  <td class="px-4 py-3">البحوث في هذا العدد</td>
                </tr>

                <tr
                  *ngFor="let publication of publications"
                  class="bg-very-light-gray bg-opacity-25"
                >
                  <td class="px-4 py-3">
                    <p class="mb-0">
                      {{ publication.subject }}
                    </p>
                    <p class="mb-0">{{ publication.researcher }}</p>
                    <div class="text-end">
                      <a
                        href="{{ environment.magazine_API }}/assets/{{
                          publication.id
                        }}"
                        class="btn btn-dark text-dark fw-bold bg-transparent"
                      >
                        <img
                          src="assets/images/file-pdf 2.svg"
                          style="width: 17px; height: 17px"
                          class="me-2"
                          alt=""
                        />
                        استعراض البحث
                      </a>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
