import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AboutUsComponent } from "./components/pages/about-us/about-us.component";
import { EditingStaffComponent } from "./components/pages/editing-staff/editing-staff.component";
import { StaffComponent } from "./components/pages/editing-staff/staff/staff.component";
import { LandingPageComponent } from "./components/pages/landing-page/landing-page.component";
import { PreviousIssuesComponent } from "./components/pages/previous-issues/previous-issues.component";
import { PreviousPublicationsComponent } from "./components/pages/previous-publications/previous-publications.component";
import { ProceduresStagesComponent } from "./components/pages/procedures-stages/procedures-stages.component";
import { PublishingFieldsGoalsComponent } from "./components/pages/publishing-fields-goals/publishing-fields-goals.component";
import { PublishingTermsComponent } from "./components/pages/publishing-terms/publishing-terms.component";
import { AboutUsPageResolver } from "./resolvers/about-us-page.resolver";
import { EditingStaffPageResolver } from "./resolvers/editing-staff-page.resolver";
import { LandingPageMagazineNumbersResolver } from "./resolvers/landing-page-magazine-numbers.resolver";
import { LandingPageResolver } from "./resolvers/landing-page.resolver";
import { PreviousIssuesPageResolver } from "./resolvers/previous-issues-page.resolver";
import { PreviousPublicationsPageResolver } from "./resolvers/previous-publications-page.resolver";
import { ProceduresStagesPageResolver } from "./resolvers/procedures-stages-page.resolver";
import { PublishingGoalsFieldsPageResolver } from "./resolvers/publishing-goals-fields-page.resolver";
import { PublishingTermsPageResolver } from "./resolvers/publishing-terms-page.resolver";
import { SingleStaffPageResolver } from "./resolvers/single-staff-page.resolver";
import { IssueResearchesComponent } from "./components/pages/issue-researches/issue-researches.component";
import { IssueResearchesPageResolver } from "./resolvers/issue-researches-page.resolver";
import { PublishingPolicyAndEthicsComponent } from "./components/pages/publishing-policy-and-ethics/publishing-policy-and-ethics.component";
import { PublishingPolicyAndEthicsPageResolver } from "./resolvers/publishing-policy-and-ethics-page.resolver";

const routes: Routes = [
  {
    path: "",
    component: LandingPageComponent,
    resolve: {
      page: LandingPageResolver,
      numbers: LandingPageMagazineNumbersResolver,
    },
  },
  {
    path: "committees",
    component: EditingStaffComponent,
    resolve: { page: EditingStaffPageResolver },
  },
  {
    path: "committees/:staffId",
    component: StaffComponent,
    resolve: {
      page: SingleStaffPageResolver,
    },
  },
  {
    path: "about-us",
    component: AboutUsComponent,
    resolve: { page: AboutUsPageResolver },
  },
  {
    path: "publishing-fields-goals",
    component: PublishingFieldsGoalsComponent,
    resolve: { page: PublishingGoalsFieldsPageResolver },
  },
  {
    path: "publishing-terms",
    component: PublishingTermsComponent,
    resolve: { page: PublishingTermsPageResolver },
  },
  {
    path: "policy-and-ethics",
    component: PublishingPolicyAndEthicsComponent,
    resolve: { page: PublishingPolicyAndEthicsPageResolver },
  },
  {
    path: "procedures-stages",
    component: ProceduresStagesComponent,
    resolve: { page: ProceduresStagesPageResolver },
  },
  {
    path: "issues",
    component: PreviousIssuesComponent,
    resolve: { page: PreviousIssuesPageResolver },
  },
  {
    path: "issues/:issueNumber",
    component: IssueResearchesComponent,
    resolve: { page: IssueResearchesPageResolver },
  },
  {
    path: "publications",
    component: PreviousPublicationsComponent,
    resolve: { page: PreviousPublicationsPageResolver },
  },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
