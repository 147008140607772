import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable } from "rxjs";
import { AboutUsPageData } from "../interfaces/about-us-page";
import { GET_ABOUT_US_PAGE } from "../queries/about-us-page.query";

@Injectable({
  providedIn: "root",
})
export class AboutUsPageResolver implements Resolve<AboutUsPageData> {
  constructor(private apollo: Apollo) {}

  resolve(): Observable<AboutUsPageData> {
    return this.apollo
      .query({
        query: GET_ABOUT_US_PAGE,
      })
      .pipe(
        map((result): AboutUsPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: "عن المجلة",
            hero: {
              title: meta.title,
              image: meta.image,
            },
            data: page,
            meta,
          };
        })
      );
  }
}
