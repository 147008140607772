import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ImageFile } from "src/app/interfaces/image-file";

import {
  PreviousIssuesPage,
  PreviousIssuesPageData,
} from "src/app/interfaces/previous-issues-page";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-previous-issues",
  templateUrl: "./previous-issues.component.html",
  styleUrls: ["./previous-issues.component.scss"],
})
export class PreviousIssuesComponent implements OnInit {
  currentPage = 1;
  totalPages!: number;
  pageSize = 50;
  searchNumber: string = "";
  issues: Issue[] = [];
  totalIssuesCount!: number;
  environment = environment;
  loading!: boolean;
  constructor(private route: ActivatedRoute, private http: HttpClient) {}
  previousIssuesData!: PreviousIssuesPage;

  numsImage!: ImageFile;

  ngOnInit() {
    const page = this.route.snapshot.data["page"] as PreviousIssuesPageData;
    this.numsImage = page.data.homePage.numsImage;
    this.previousIssuesData = page.meta;
    this.getPublications();
  }
  getPublications(): void {
    this.loading = true;
    try {
      this.http
        .get<any[]>(
          `${environment.magazine_API}/api/issues?number=${this.searchNumber}&page=${this.currentPage}&pagesize=${this.pageSize}`
        )
        .subscribe((data: any) => {
          this.issues = data.data;
          this.totalIssuesCount = data.totalCount;
          this.totalPages = Math.ceil(data.totalCount / this.pageSize);
          this.loading = false;
          window.scrollTo(0, 0);
        });
    } catch (error) {}
  }

  showItemsByPage(page: number) {
    this.currentPage = page;
    const listItems = document.getElementsByClassName("page-item");
    for (let i = 0; i < listItems.length; i++) {
      const item = listItems[i];
      if (page === i + 1) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    }
    this.getPublications();
  }

  searchForPublication(event: Event) {
    this.searchNumber = (event.target as HTMLInputElement).value.trim();
    this.currentPage = 1;
    this.getPublications();
  }
}

export interface Issue {
  id: string;
  name: string;
  number: string;
  count: number;
  year: string;
  date: string;
  hasFile?: boolean;
}
