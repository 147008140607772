<app-page>
  <page-header></page-header>
  <page-content>
    <div id="terms" class="my-5">
      <div class="container">
        <div class="row">
          <div class="col-md-3 mb-5 mb-md-0">
            <img
              class="rounded-3 d-block w-100 img-fluid cover"
              style="aspect-ratio: 1"
              [src]="publishingTermsData.publishingTermsImage | imageLink"
              [alt]="publishingTermsData.publishingTermsImage.title"
            />
          </div>
          <div class="col">
            <h5 class="fw-bold">لغة النشر</h5>
            <div [innerHTML]="publishingTermsData.language" class=""></div>

            <div class="my-5"></div>

            <section class="my-5">
              <h5 class="fw-bold section-heading">قواعد وشروط النشر:</h5>
              <div [innerHTML]="publishingTermsData.termsAndRules" class="">
                
              </div>
            </section>
            <section class="my-5">
              <h5 class="fw-bold section-heading">التوثيق والمراجع:</h5>
              <div [innerHTML]="publishingTermsData.docsAndReferences" class="">
                
              </div>
            </section>

            <section class="my-5">
              <h5 class="fw-bold section-heading">الإجراءات والمراحل:</h5>
              <br />
              <div
                [innerHTML]="publishingTermsData.proceduresAndStages"
                class="px-4"
              ></div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
